import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Amplify } from 'aws-amplify'
import theme from './MyTheme'
import Register from 'pages/Register'

import awsconfig from './aws-exports'
Amplify.configure(awsconfig)

const queryClient = new QueryClient({
    defaultOptions : {
        queries : {
            staleTime : Infinity,
        },
    },
})

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <QueryClientProvider client={queryClient}>
                <Register />
            </QueryClientProvider>
        </ThemeProvider>
    )
}

export default App
