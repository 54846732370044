import { Link, Stack } from '@mui/material'
import { grey } from '@mui/material/colors'
import { Body } from 'components/styledFonts'
import { urls } from 'config/urls'


const FooterLink = ({ label, url }) => 
    <Link
        href={url}
        underline="hover"
        color={grey[800]}
    >
        <Body>{label}</Body>
    </Link>


const Footer = () => {
    return (
        <Stack
            alignItems="center"
            width="100%"
            bgcolor={grey[100]}
            borderTop="1px solid #ccc"
            pt={3}
            pb={8}
            gap={3}
        >
            <Stack
                direction="row"
                gap={4}
            >
                {Object.keys(urls.footer).map(l => (
                <FooterLink
                    key={l}
                    label={l}
                    url={urls.footer[l]}
                />
                ))}
            </Stack>
            <Body>Copyright © Netamoto Inc. All rights reserved.</Body>
        </Stack>
    )
}

export default Footer
