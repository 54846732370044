import { useEffect, useState } from 'react'
import { useController } from 'react-hook-form'
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    IconButton,
    InputAdornment,
    MenuItem,
    Radio,
    RadioGroup as MuiRadioGroup,
    Select as MuiSelect,
    Stack,
    TextField as MuiTextField,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { styled } from '@mui/material/styles'
import { red } from '@mui/material/colors'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ja from 'date-fns/locale/ja'
import { Body, H5 } from 'components/styledFonts'

registerLocale('ja', ja)

const StyledText = styled(MuiTextField)({
    '& .MuiOutlinedInput-input' : {
        backgroundColor : '#fff',
    },
})

export const Required = () => <Body component="span" color={red[400]} pl={0.3}>*</Body>


export const TextField = ({ name, control, ...props }) => {
    const { field : { ref, ...rest }, fieldState : { error } } = useController({ name, control })

    return (<>
        <StyledText
            name={name}
            inputRef={ref}
            error={!!error}
            size="small"
            {...props}
            {...rest}
        />
        {!!error && <FormHelperText error>{error.message}</FormHelperText>}
    </>)
}

export const LabeledTextField = ({ name, label, control, required, ...props }) => {
    return (
        <Stack
            width={props.fullWidth ? "100%" : "auto"}
            gap={0.5}
        >
            <H5>
                {label || name}
                {required &&
                <Required />
                }
            </H5>
            <TextField
                name={name}
                control={control}
                {...props}
            />
        </Stack>
    )
}

export const CharacterLimitField = ({ limit, disableEnter, watch, ...props }) => {
    const [count, setCount] = useState(0)

    useEffect(() => setCount((watch || '').length), [watch])

    if (disableEnter) {
        props.inputProps = {
            onKeyDown : e => {
                if (e.key === 'Enter') {
                    e.preventDefault()
                }
            }
        }
    }

    return (
        <Stack
        >
            <LabeledTextField
                {...props}
            />
            <Body>※{limit}字以内（残り{limit - count}文字）</Body>
        </Stack>
    )
}

export const Select = ({ name, control, list, handleChange, ...props }) => {
    const { field : { ref, onChange, ...rest }, fieldState : { error } } = useController({ name, control })

    return (<>
        <FormControl
            size="small"
            fullWidth
        >
            <MuiSelect
                displayEmpty
                ref={ref}
                sx={{ fontSize : 14 }}
                onChange={e => {
                    onChange(e)
                    if (handleChange) handleChange()
                }}
                {...rest}
                {...props}
            >
                {list?.map(l => (
                    <MenuItem
                        key={l.label}
                        value={l.value}
                        sx={{ fontSize : 14 }}
                    >
                        {l.label}
                    </MenuItem>
                ))}
            </MuiSelect>
        </FormControl>
        {!!error && <FormHelperText error>{error.message}</FormHelperText>}
    </>)
}

export const RadioGroup = ({ name, control, list, ...props }) => {
    const { field : { ref, ...rest }, fieldState : { error } } = useController({ name, control })

    return (<>
        <FormControl error={!!error} fullWidth>
            <MuiRadioGroup
                ref={ref}
                sx={{gap : 1}}
                {...props}
                {...rest}
            >
                {list.map(r => (
                    <FormControlLabel
                        key={r}
                        value={r}
                        label={<Body>{r}</Body>}
                        control={<Radio size="small" />}
                    />
                ))}
            </MuiRadioGroup>
        </FormControl>
        {!!error && <FormHelperText error>{error.message}</FormHelperText>}
    </>)
}


export const CheckboxGroup = ({ name, control, list, row=true, sx, ...props }) => {
    const { field : { ref, value, onChange, ...rest }, fieldState : { error } } = useController({ name, control })

    const handleChange = e => {
        if (e.target.checked) {
            return [...new Set([...value, e.target.value])]
        }
        else {
            return value.filter(v => v !== e.target.value)
        }
    }

    return (<>
        <FormGroup ref={ref} onChange={e => onChange(handleChange(e))} row={row} {...rest}>
            {list.map(v => (
            <FormControlLabel
                key={v}
                label={<Body>{v}</Body>}
                control={<Checkbox value={v} size="small" color="primary" checked={value.includes(v)} />}
                {...rest}
                {...props}
                sx={{
                    mr : 3,
                    '& .MuiFormControlLabel-label' : {
                        ml : -0.3,
                    },
                    ...sx,
                }}
            />
            ))}
        </FormGroup>
        {!!error && <FormHelperText error>{error.message}</FormHelperText>}
    </>)
}

export const DatePicker = ({ name, control, ...props }) => {
    const { field : { ref, value, ...rest }, fieldState : { error } } = useController({ name, control })

    return (<>
        <FormControl error={!!error}
            sx={{
                '& .MuiInputBase-root input' : {
                    p : '8.5px 14px',
                },
            }}
        >
            <ReactDatePicker
                locale="ja"
                showIcon
                dateFormat={props.showTimeSelect ? 'yyyy/MM/dd hh:mm' : 'yyyy/MM/dd'}
                timeIntervals={60}
                ref={ref}
                selected={value}
                customInput={<StyledText error={!!error} />}
                {...rest}
                {...props}
            />
        </FormControl>
        {!!error && <FormHelperText error>{error.message}</FormHelperText>}
    </>)
}

export const SearchWord = ({ value, handleChange, handleClick, placeholder, ...props }) => {

    return (
        <Box
            pb={1}
            maxWidth={400}
            {...props}
        >
            <MuiTextField
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
                InputProps={{
                    endAdornment : (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={handleClick}
                            >
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                size="small"
                fullWidth
            />
        </Box>
    )
}

