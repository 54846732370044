import { Box, Checkbox, FormControlLabel, Stack } from '@mui/material'
import { grey } from '@mui/material/colors'
import { Required } from 'components/Inputs'
import { H5, Body, SmallBody } from 'components/styledFonts'

const TOS = 
`ネタもと利用規約（以下「本規約」といいます。）は、株式会社ネタもと（以下「当社」といいます。）が提供するＰＲプラットフォーム「ネタもと」について、登録ユーザーの皆様がそのご利用にあたり、遵守していただかなければならない事項及び当社と登録ユーザーの皆様との間の権利義務関係を定めています。本サービス登録ユーザーの皆様は、本規約に同意したものと見なされます。

第1条（目的）
　　「ネタもと」は、企業・団体が求める商品・サービス等に関する情報（以下、「PR情報」といい、その内容は、第2条第5項の定めるところによります。）の発信の機会と、報道関係者が求めるPR情報の取得の機会を繋ぐため、インターネット上における環境等を提供することを目的としています。
　　「ネタもと」（以下「本サービス」といいます。）では、以下のようなPR情報の発信、受信又はこれらに関連したサービスないしツールを提供しています（理由の如何を問わず本規約第16条によりサービス内容が変更された場合は、当該変更後のサービスを含みます。）。
　　(1)　PR情報の提供元たる企業・団体が、当社の提供するウェブサイト（以下「当社ウェブサイト」といい、その内容は、第2条第4項の定めるところによります。）においてPR情報を掲載し、PR情報を求める報道関係者が、当社ウェブサイトにおいて、PR情報の検索、取得及び利用ができるサービス
　　(2)　報道関係者が探しているPR情報を、その提供元たる企業・団体に公開して、PR情報の提供を求めるサービス
　　(3)　報道関係者の求めるPR情報の特性や公開方法等、PR情報の提供元たる企業・団体において、PR情報を提供するに際して有用な情報を内容とするセミナー及び勉強会
　　(4)　前各号のサービスに付随・関連するサービス

第2条（定義）
　　本規約において使用する用語の定義は以下のとおりとします。
　1.　「知的財産権」とは、著作権、特許権、実用新案権、商標権、意匠権その他の知的財産に関する権利（それらの権利を登録又は取得し、移転、変更その他の処分、使用する権利を含む。）を意味します。
　2.　「登録ユーザーA」とは、企業、団体で、第3条に基づき本サービスの利用者としての登録されたものを意味します。
　3.　「登録ユーザーB」とは、新聞社、通信社、出版社、テレビ局、ラジオ局等の報道機関において、ディレクター、記者、編集、制作等の業務に従事する個人、及び、これら報道機関に対して定期的に寄稿している記者個人で、第3条に基づき本サービスの利用者としての登録がなされたものを意味します。
　4.　「当社ウェブサイト」とは、当社が、登録ユーザーA・Bに対して本サービスを提供するインターネット上のサイトを意味します。
　5.　「PR情報」とは、登録ユーザーAが本サービスを通じて発信した、プレスリリース等の登録ユーザーAの商品・サービス等に関する情報を意味します。

第3条（登録）
　1.　本サービスの利用を希望する者（以下「登録希望者」といいます。）は、当社に対して、本規約を遵守することに同意し、かつ、名称ないし氏名、住所、連絡先、eメールアドレス等、当社の求める情報（以下「登録情報」といいます。）を、登録ユーザーAにおいては、当社指定の申込書（以下「申込書」といいます。）に記入して、また、登録ユーザーBにおいては、当社ウェブサイトにおける当社所定のフォームに入力して、それぞれ本サービス利用のための登録申請をすることができます。
　2.　登録の申請は、必ず本サービスを利用する個人又は企業・団体において行わなければならず、当社の書面による許可がなければ代理人による登録申請をすることはできません。
　3.　登録希望者は、登録申請にあたり、登録情報として真実かつ最新の情報を当社に提供しなければならず、事実に反する情報を提供してはなりません。他人若しくは他社のメールアドレスでの登録は禁止します。
　4.　当社は、登録希望者の登録の許否を判断し、当社が登録を認める場合には、登録ユーザーA・Bに対しユーザーIDを付与し、この付与の時点で当社と登録ユーザーA・Bとの間に、本条第1項の登録申請の内容にて本サービスの利用契約（以下「利用契約」といいます。）が成立し、登録希望者の登録ユーザーA・Bとしての登録は完了したものとします。
　5.　当社は、登録希望者が、以下の各号のいずれかの事由に該当する場合は、登録を拒否することがあります。
　　(1)　本規約に違反し、又は、違反するおそれがある場合
　　(2)　当社に提供された登録情報の全部又は一部につき事実と異なる記載があった場合
　　(3)　過去に本サービスの利用の登録を取り消された者である場合
　　(4)　その他、当社が登録を不適当と判断した場合

第4条（登録情報の取扱い等）
　1.　登録ユーザーA・Bは、登録情報に変更があった場合又は誤りがあった場合は、当社に対し、遅滞なく所定の方法により当該事項を通知し、当社から要求された資料を提出するものとします。
　2.　当社は登録ユーザーA・Bの登録情報を、当社の定める「個人情報の取り扱い」（https://netamoto.co.jp/privacy/）に従って取り扱うものとし、登録ユーザーはかかる取り扱いについて予め同意したものとします。

第5条（登録の取消）
　1.　当社は、登録ユーザーA・Bが、以下の各号のいずれかの事由に該当し、また、該当するおそれがある場合には、事前に通知することなく、当該登録ユーザーA・Bについて本サービスの利用を一時的に停止し、又は登録ユーザーA・Bとしての登録を取り消すことができます。この場合、当社は、当該登録ユーザーA・Bに対して、少なくとも事後においてその処置内容を通知します。当社は、本項に基づき当社が行った行為により登録ユーザーA・Bに生じた損害について一切の責任を負いません。但し、当社に故意・重過失のある場合はこの限りでありません。
　　(1)　第3条第5項に定める各号のいずれかに該当する事実が発覚した場合
　　(2)　第8条第2項に定める各号のいずれかに該当する行為をした場合
　　(3)　6ヶ月以上本サービスの利用がなく、当社からの連絡に対して応答がない場合
　　(4)　その他、登録ユーザーA・Bの登録継続が、第1条に定める本サービスの目的に反する等、当社が適当でないと合理的に判断した場合
　2.　登録ユーザーAは、申込書記載の期間内においては、利用契約を解除することができません。ただし、当社から書面による許可を得た場合においては、申込書記載の期間分の利用料金全額を即時に支払って利用契約を解除することができます。
　3.　登録ユーザーBは、当社ウェブサイトにおける当社所定のフォームに入力することにより、利用契約を解除し、自己の登録ユーザーBとしての登録を取り消すことができます。

第6条（登録有効期間）
　1.　当社と登録ユーザーAとの間の利用契約は、第3条第4項に基づき登録が完了した日から、申込書記載の期間内、又は、前条によりその登録取消が完了した日まで有効に存続します。但し、申込書記載の期間は、その期間満了の１か月前までに、当社又は登録ユーザーAのいずれからも解約の申し出がない場合には、同一の条件にてさらに１年間更新されるものとし、以降も同様とします。
　2.　当社と登録ユーザーBとの間の利用契約は、第3条第4項に基づき登録が完了した日から、前条によりその登録取消が完了した日まで有効に存続します。
　3.　前2項にかかわらず、本規約第10条、第13条、第19条の規定は、利用契約期間終了後もなお適用されます。また、利用契約期間終了後といえども、利用契約期間前に生じた事由に基づく法律関係については、なお、本規約の規定が適用されます。

第7条（利用料金）
　1.　本サービスの利用料金については申込書記載のとおりとします。登録ユーザーAは、利用料金を当社が定める期日までに、当社の指定した銀行預金口座へ振り込むものとします。
　2.　利用料金の支払いに関する手数料は登録ユーザーAの負担とします。

第8条（本サービスの利用）
　1.　登録ユーザーA・Bは、第6条により利用契約が有効の期間内に限り、本規約の目的及び本規約に違反しない範囲内で、当社の定める方法に従い、本サービスを利用することができます。
　2.　登録ユーザーA・Bは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為をしてはなりません。
　　(1)　当社、本サービスの他の利用者又は第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利・利益を侵害する行為
　　(2)　当社、本サービスの他の利用者又は第三者に対する誹謗・中傷及びこれに準ずる行為（製品又はサービスに関する個人的なクレーム等を含みます。）
　　(3)　第11条第6項の各号に違反するPR情報を発信する行為
　　(4)　犯罪行為、その助長行為、公序良俗に反する行為その他法令に違反する行為
　　(5)　当社又は登録ユーザーA・Bが所属する業界団体の内部規則に違反する行為
　　(6)　コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報を送信する行為、及び、本サービスのシステムに不正にアクセスする行為
　　(7)　PR情報について、そのPR情報の発信者たる登録ユーザーAと当該PR情報の商品・サービスに関する情報を切り離して利用する行為
　　(8)　虚偽のPR情報を掲載し、又は、PR情報に虚偽の情報を加える形で改変する行為
　　(9)　当社が定める一定のデータ容量以上のデータを、本サービスを通じて送信する行為
　　(10)　第7条に定める利用料金の滞納
　　(11)　当社による本サービスの運営を妨害する行為
　　(12)　本規約の他の規定に違反する行為
　　(13)　その他、第１条に定める本サービスの目的に反する等、当社が不適当と合理的に判断した行為
　3.　当社は、登録ユーザーA・Bの行為が前項各号のいずれかに該当し、又は該当するおそれがある場合には、事前に通知することなく、PR情報の全部又は一部を削除する等必要な処置を講ずることができます。この場合、当社は、当該処置をとられた登録ユーザーA・Bに対して、少なくとも事後においてその処置内容を通知します。当社は、本項に基づき当社が行った措置によって、登録ユーザーA・Bに生じた損害について一切の責任を負いません。但し、当社に故意・重過失のある場合はこの限りでありません。

第9条（パスワード及びユーザーIDの管理）
　1.　登録ユーザーA・Bは、自己の責任において、パスワード及びユーザーIDを管理・保管するものとし、譲渡、貸与、担保供与、名義変更その他名称の如何を問わずこれを第三者に利用させる行為を禁止します。
　2.　パスワード又はユーザーIDの管理不十分、使用上の過誤、第三者の使用等による損害の責任は登録ユーザーA・Bが負うものとし、当社は一切の責任を負いません。但し、当社に故意・重過失のある場合はこの限りでありません。
　3.　登録ユーザーA・Bは、パスワード又はユーザーIDが盗まれたり、第三者に使用されていることが判明した場合には、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。

第10条（本サービスの権利帰属等）
　　当社ウェブサイトないし本サービスに関する知的財産権は、全て当社又は当社にライセンスを許諾している者に帰属しており、本規約第3条に定める登録に基づく本サービスの利用許諾は、当社ウェブサイトないし本サービスに関する当社又は当社にライセンスを許諾している者の知的財産権の譲渡を意味するものではありません。登録ユーザーA・Bは、いかなる理由によっても、当社又は当社にライセンスを許諾している者の知的財産権を侵害する行為及びそのおそれのある行為（逆アセンブル、逆コンパイル、リバースエンジニアリングを含みますが、これに限定されません。）をしてはなりません。

第11条（PR情報の取扱い）
　1.　PR情報は、第1条に定める本サービスの目的、特にその発信の相手方たる登録ユーザーBが報道関係者であることに鑑み、本規約に違反しない限りにおいて、登録ユーザーAの個別の承諾なしに当社、登録ユーザーB又は当社が許諾する第三者が直接又は二次的に利用できるものとし、登録ユーザーAはこれを承諾します。
　2.　登録ユーザーAは、前項と同様の目的に鑑み、当社にPR情報を提供すると同時に、当該PR情報を本サービスの目的を履行するために使用する権利を無償で当社に対して許諾したものとみなします。また、登録ユーザーAは著作者人格権を当社に対して一切行使しないものとします。
　3.　当社は、PR情報に関して、登録ユーザーBがこれを本規約に違反しない限りにおいて本条第1項の通り利用することを許諾します。
　4.　当社は、登録ユーザーAのPR情報が登録ユーザーBによっていかなる利用をされ、あるいは全く利用されなかったとしても、何ら責任を負いません。
　5.　当社ウェブサイト上に掲載されたPR情報について、当社は、かかるPR情報を参考にして行われた取引に起因する損害について一切責任を負わないものとします。
　6.　登録ユーザーAが本サービスに関連して当社に提供したPR情報が、以下の各号のいずれかに該当し、又は該当するおそれがある場合には、登録ユーザーAに事前に通知することなく、当該PR情報の全部又は一部を削除することができるものとします。この場合、当社は、その発信したPR情報を削除された登録ユーザーAに対して、少なくとも事後においてその処置内容を通知します。当社は、本項に基づき当社が行った措置により登録ユーザーAに生じた損害について一切の責任を負いません。但し、当社に故意・重過失のある場合はこの限りでありません。
　　(1)　当社、本サービスの他の利用者又は第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利・利益を侵害するもの
　　(2)　当社、本サービスの他の利用者又は第三者に対する誹謗・中傷及びこれに準ずるもの（製品又はサービスに関する個人的なクレーム等を含みます。）
　　(3)　犯罪を構成するもの、犯罪行為を助長するもの、公序良俗に反するものその他法令に違反するもの
　　(4)　当社又は登録ユーザーA・Bが所属する業界団体の内部規則に違反するもの
　　(5)　事実と異なる記載を含むもの
　　(6)　誤解を生じさせやすい表現を含むもの
　　(7)　明らかに他人になりすまして投稿したと思われるもの
　　(8)　内容が出会い系サイトやアダルト系サイトに類するもの
　　(9)　内容がねずみ講やねずみ講まがい・マルチ商法関連に類するもの
　　(10)　内容が本サービスに競合する類のものである場合
　　(11)　単なる記号や文字の羅列等
　　(12)　内容が当社で理解できず、判断が出来ない場合
　　(13)　本規約の他の規定に反するもの
　　(14)　その他、第１条に定める本サービスの目的に反する等、当社が不適当と判断するもの
　7.　当社は、登録ユーザーAが送受信したメッセージ、及び、PR情報を運営上一定期間保存していた場合であっても、かかるコンテンツを保存する義務を負うものではなく、当社はいつでもこれらのコンテンツを削除できるものとします。なお、当社は本条に基づき当社が行った措置により登録ユーザーAに生じた損害について一切の責任を負いません。但し、当社に故意・重過失のある場合はこの限りでありません。

第12条（保証の否認及び免責）
　1.　当社は、PR情報の内容（登録ユーザーAが本サービスに関連して提供した一切の情報を含む。本条において以下同じ。）につき如何なる保証も行うものではなく、当社は登録ユーザーA・BがかかるPR情報を利用したことにより発生する損害、トラブル等に関しては一切の責任を負いません。PR情報の内容に誤りが発見された場合その他当社が必要と判断した場合において、当社が適宜情報をアップデートする場合がありますが、かかるアップデートを行う義務を当社が負うものではありません。さらに、登録ユーザーBが当社から直接又は間接にPR情報又は他の登録ユーザーAに関する情報を得た場合であっても、当社は登録ユーザーBに対し本規約において規定されている内容を超えて如何なる保証も行うものではありません。
　2.　登録ユーザーA・Bは、本サービスを利用することが、登録ユーザーA・Bに適用のある法令、業界団体の内部規則等に違反するか否かを自己の責任と費用に基づいて調査するものとし、当社は、登録ユーザーA・Bによる本サービスの利用が、登録ユーザーに適用のある法令、業界団体の内部規則等に適合することを何ら保証するものではありません。
　3.　当社は、当社による本サービスの提供の中断、停止、利用不能又は変更、登録ユーザーA・Bのメッセージ、又は、登録ユーザーAにより提供されたPR情報の削除又は消失、登録ユーザーA・Bの登録取消、本サービスの利用によるデータの消失又は機器の故障若しくは損傷、その他本サービスに関連して登録ユーザーA・Bが被った損害につき、賠償する責任を一切負わないものとします。但し、当社に故意・重過失のある場合はこの限りでありません。
　4.　登録ユーザーA・Bが本サービスを利用する環境によっては、登録ユーザーA・Bが利用するパソコンがダウンするなどの障害が生じる場合がありますが、当社はこれらの登録ユーザーA・Bが有する機器の障害等に関して、一切責任を負わないものします。但し、当社に故意・重過失のある場合はこの限りでありません。
　5.　当社ウェブサイトから他のウェブサイトへのリンク又は他のウェブサイトから当社ウェブサイトへのリンクが提供されている場合でも、当社は、当社ウェブサイト以外のウェブサイト及びそこから得られる情報に関して如何なる理由に基づいても一切の責任を負わないものとします。

第13条（秘密保持）
　1.　本規約において「秘密情報」とは、本サービスに関連して、当社又は登録ユーザーA・Bが、相手方より書面、口頭若しくは記録媒体等により提供若しくは開示され、又は、知得した、相手方に関する技術、営業、業務、財務又は組織に関する全ての情報を意味します。但し、以下のものについては、秘密情報から除外します。
　　(1)　相手方から提供若しくは開示がなされたとき又は知得したときに、既に一般に公知となっていた、又は既に知得していたもの
　　(2)　相手方から提供若しくは開示がなされ又は知得した後に、自己の責めに帰すべからざる事由により刊行物その他により公知となったもの
　　(3)　提供又は開示の権限のある第三者から秘密保持義務を負わされることなく適法に取得したもの
　　(4)　秘密情報によることなく単独で開発したもの
　　(5)　相手方から秘密保持の必要なき旨書面で確認されたもの
　2.　当社及び登録ユーザーA・Bは、秘密情報を本サービスの目的のみに利用するとともに、相手方の書面による承諾なしに第三者に相手方の秘密情報を提供、開示又は漏洩しないものとします。
　3.　第2項の定めに拘わらず、当社及び登録ユーザーA・Bは、法律、裁判所又は政府機関の強制力を伴う命令、要求又は要請に基づき、秘密情報を開示することができます。但し、当該命令、要求又は要請があった場合、速やかにその旨を相手方に通知しなければなりません。
　4.　当社及び登録ユーザーA・Bは、秘密情報を記載した文書又は記録媒体等を複製する場合には、事前に相手方の書面による承諾を得ることとし、複製物の管理についても第2項により厳重に行うものとします。
　5.　当社及び登録ユーザーA・Bは、相手方から求められた場合にはいつでも、遅滞なく、相手方の指示に従い、秘密情報並びに秘密情報を記載又は包含した書面その他の記録媒体物及びその全ての複製物を返却又は廃棄しなければなりません。
　6.　登録ユーザーA・Bは、当社が登録ユーザーA・Bを識別できないように加工した統計資料を作成すること、かかる統計資料を、当社において新規サービスの開発、マーケティング調査等に利用すること、及び、当社の業務提携先等に提供することを予め承諾します。
　7.　登録ユーザーA・Bの行為が第三者に不利益を及ぼし、あるいは、法令に反すると当社が判断した場合、裁判所、検察庁、警察又はこれらに準じた権限を有する機関への協力を要求された場合、及び、本サービスを違法行為に使用した人物の特定のために必要な場合には、当社は、登録情報、PR情報等その所持する情報をその権利・利益を侵害され、あるいは、侵害されるおそれのある第三者や警察又は関連諸機関に通知致します。登録ユーザーAは、自分で発信、提供又は開示したコンテンツに関して刑罰を受けたり、損害賠償請求をされたり、第三者から異議を申し立てられたりする可能性があります。

第14条（反社会的勢力の排除）
　1.　登録ユーザーA・Bは、現在、暴力団、暴力団員、暴力団員でなくなった時から５年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ、特殊知能暴力集団等その他これらに準ずる者（以下これらの者を「暴力団員等」という。）に該当しないこと及び次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約するものとします。
　　(1)　暴力団員等が経営を支配していると認められる関係を有すること
　　(2)　暴力団員等が経営に実質的に関与していると認められる関係を有すること
　　(3)　自己、自社又は第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってするなど、不当に暴力団員等を利用していると認められる関係を有すること
　　(4)　暴力団員等に対して資金等を提供し、又は便宜を供与するなどの関与をしていると認められる関係を有すること
　　(5)　役員又は経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有すること
　2.　登録ユーザーA・Bは、自ら又は第三者を利用して次の各号に該当する行為を行わないことを確約するものとします。
　　(1)　暴力的な要求行為
　　(2)　法的な責任を超えた不当な要求行為
　　(3)　取引に関して、脅迫的な言動をし、又は暴力を用いる行為
　　(4)　風説を流布し、偽計又は威力を用いて当社の信用を棄損し、又は当社の業務を妨害する行為
　　(5)　その他前各号に準ずる行為
　3.　当社は、登録ユーザーA・Bが、第1項のいずれかに該当し、若しくは第2項のいずれかに該当する行為をし、又は第1項に基づく確約に関して虚偽の申告をしたことが判明した場合には、催告その他何らの手続きを要することなく、直ちに登録ユーザーA・Bとの取引の全部又は一部を停止し、又は登録ユーザーA・Bとの契約の全部又は一部を解約することができるものとします。なお、取引の停止又は契約の解約に起因し又は関連して登録ユーザーA・Bに損害等が生じた場合であっても、当社が何ら責任を負うものではないことを登録ユーザーA・Bは確認するものとします。
　4.　登録ユーザーA・Bが第1項又は第2項の確約に反したことにより当社が損害を被った場合、登録ユーザーA・Bはその損害を賠償する義務を負うものとします。

第15条（本サービスの停止）
　1.　当社は、以下のいずれかに該当する場合には、本サービスの利用の全部又は一部を一時的又は永久的に停止することができるものとします。この場合、当社は、当該登録ユーザーA・Bに対して、少なくとも事後においてその内容を通知します。
　　(1)　本サービスに係るコンピューター・システムの点検又は保守作業を定期的又は緊急に行う場合
　　(2)　コンピューター、通信回線等が事故により停止した場合
　　(3)　火災、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合
　　(4)　その他、第1条に定める目的を達成できない等、当社が停止又は中断を必要と合理的に判断した場合
　2.　当社は、前項により当社が行った措置に基づき登録ユーザーA・Bに生じた損害について一切の責任を負いません。但し、当社に故意・重過失のある場合はこの限りでありません。

第16条（本規約等の変更）
　1.　当社は、本規約、当社ウェブサイトに掲載する本サービスに関するルール等の諸規定、及び、本サービスの内容（以下「本規約等」といいます。）を自由に変更できるものとします。
　2.　当社は、前項に基づき、本規約等を変更した場合においては、登録ユーザーA・Bに対し、少なくとも事後において当該変更内容を通知するものとし、その通知後、登録ユーザーA・Bが本サービスの利用を継続した場合、又は、当社の定める期間内に登録取消の手続をとらなかった場合には、登録ユーザーA・Bは、その変更に同意したものとみなします。

第17条（地位の譲渡等）
　1.　登録ユーザーA・Bは、当社の書面による事前の承諾なく、利用契約上の地位又は本規約等に基づく権利若しくは義務につき、第三者に対し、譲渡、担保設定、その他の処分をすることはできません。
　2.　当社が本サービスの営業を他社に譲渡した場合には、当該営業譲渡に伴い利用契約上の地位、本規約等に基づく権利及び義務並びに登録ユーザーA・Bの登録情報及びPR情報等のコンテンツを当該営業譲渡の譲受人に譲渡することができるものとし、登録ユーザーA・Bは、かかる譲渡につき本項において予め同意したものとします。

第18条（紛争処理及び損害賠償）
　1.　登録ユーザーA・Bは、本規約に違反することにより、又は、本サービスに関連して当社に損害を与えた場合、当社に対しその損害を賠償しなければなりません。
　2.　登録ユーザーA・Bが、本規約に違反することにより、又は、本サービスに関連して他の登録ユーザーA・Bその他の第三者からクレームを受け、又は、それらの者との間で紛争を生じた場合においては、当該登録ユーザーA・Bは、自己の費用と責任において当該クレーム又は紛争（以下「当該クレーム等」といいます。）を解決するものとし、当社は、一切の責任を負いません。なお、登録ユーザーA・Bは、当該クレーム等を受けた場合、当該クレーム等を解決した場合、及び、当社が要請した場合においては、当社に対し、当該クレーム等について報告をしなければなりません。
　3.　登録ユーザーA・Bによる本サービスの利用に関連して、当社が、他の登録ユーザーA・Bその他の第三者から権利侵害その他の理由により何らかの請求を受けた場合は、登録ユーザーA・Bは当該請求に基づき当社が当該第三者に支払を余儀なくされた金額を賠償しなければなりません。

第19条（専属管轄裁判所）
　　当社と登録ユーザーA・Bとの間で訴訟が生じた場合、その訴額に応じて、東京簡易裁判所又は東京地方裁判所を第一審の専属管轄裁判所とします。

第20条（完全合意）
　　本規約中に別段の定めがない限り、本規約は、本規約に含まれる事項に関する当社と登録ユーザーA・Bとの完全な合意を構成し、口頭又は書面を問わず、本規約に含まれる事項に関する当社と登録ユーザーA・Bとの事前の合意、表明及び了解に優先します。

第21条（協議解決）
　　当社及び登録ユーザーA・Bは、本規約に定めのない事項又は本規約の解釈に疑義が生じた事項については、互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします。


（附則）
2004年9月1日　制定・施行
2018年3月1日　改訂
2018年4月1日　改訂
2021年1月22日　最終改訂
`

const TermsOfService = ({ setAgree }) => {
    return (
        <Stack
            maxWidth={1000}
            p={2}
            width="100%"
            border={`1px solid ${grey[300]}`}
            bgcolor="#fff"
            gap={2}
        >
            <H5>利用規約について<Required /></H5>
            <Stack>
                <Body>弊社の利用規約に同意される場合はチェックボックスをクリックしてください。ご登録いただく前に必ずお読みください。</Body>
                <Box
                    height={150}
                    border={`1px solid ${grey[700]}`}
                    p={2}
                    sx={{
                        overflowY : "scroll"
                    }}
                >
                    <Stack
                        gap={1}
                    >
                        <SmallBody sx={{ textAlign : "center" }}>ネタもと利用規約</SmallBody>
                        <SmallBody sx={{ textAlign : "right" }}>株式会社ネタもと</SmallBody>
                        <SmallBody
                            sx={{
                                whiteSpace : "pre-wrap"
                            }}
                        >
                            {TOS}
                        </SmallBody>
                    </Stack>
                </Box>
            </Stack>
            <Stack
                width="100%"
                alignItems="center"
            >
                <FormControlLabel
                    label={<Body>利用規約に同意します。</Body>}
                    control={<Checkbox />}
                    onChange={e => setAgree(e.target.checked)}
                />
            </Stack>
        </Stack>
    )
}

export default TermsOfService
