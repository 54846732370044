import useMyMutation from './useMyMutation'
import { API } from 'aws-amplify'
import { apiName } from 'config/api'
import { useAtom } from 'jotai'
import { messageAtom } from 'store/commonAtoms'

export const useAccountRequestMutation = () => {
    const [, setMessage] = useAtom(messageAtom)

    return useMyMutation({
        mutationFn : async (param) => {
            const ret = await API.post(apiName, '/items/postAccountRequest', { body : param })
            return ret
        },
        onSuccessFn : (ret) => {
            const msg = { type : 'info', text : 'アカウント発行申請を行いました。' }
            setMessage(msg)
        },
    })
}