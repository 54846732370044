import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Link, Stack } from '@mui/material'
import { grey } from '@mui/material/colors'
import { LabeledTextField, TextField, Select, RadioGroup, Required } from 'components/Inputs'
import { H3, H5, Body } from 'components/styledFonts'
import { BlueContainedButton } from 'components/styledButtons'
import TermsOfService from './TermsOfService'
import ConfirmModal from 'modals/ConfirmModal'
import { link } from 'config/links'
import { messages } from 'config/messages'


const defaultValues = {
    所属企業 : "",
    担当媒体_1 : "",
    担当媒体_2 : "",
    担当媒体_3 : "",
    姓 : "",
    名 : "",
    電話番号 : "",
    メールアドレス : "",
    メールアドレス_確認用 : "",
    ネタもとを知った経緯 : '',
    リリース情報案内メール : "受信する",
}

const schema = yup.object().shape({
    所属企業 : yup
        .string()
        .required(messages.REQUIRED)
    ,
    担当媒体_1 : yup
        .string()
        .required(messages.REQUIRED)
    ,
    姓 : yup
        .string()
        .required(messages.REQUIRED)
    ,
    名 : yup
        .string()
        .required(messages.REQUIRED)
    ,
    電話番号 : yup
        .string()
        .required(messages.REQUIRED)
        .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
            , { message : messages.PHONENUMBER_VALIDATION } )
    ,
    メールアドレス : yup
        .string()
        .required(messages.REQUIRED)
        .matches(/^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/
            , { message : messages.MAILADDRESS_VALIDATION })
    ,
    メールアドレス_確認用 : yup
        .string()
        .required(messages.REQUIRED)
        .oneOf([yup.ref('メールアドレス'), null], messages.MAILADDRESS_MATCH_FAILED)
})

const circumstances = [{ label : '選択してください', value : '' }]
    .concat(
        [
            'ネタもとを利用している記者からの紹介',
            'ネタもと社員からの紹介',
            '当社ホームページ',
            '過去にネタもとに登録していたことがある',
            'その他',
        ].map(l => ({ label : l, value : l }))
    )

const yesno = ['受信する', '受信しない']

const border = `1px solid ${grey[300]}`

const InputBox = ({ children }) => {
    return (
        <Box
            width="100%"
            borderBottom={border}
            py={2}
            sx={{
                px : { mobile : 3, xs : 1 }
            }}
        >
            {children}
        </Box>
    )
}

const LV = ({ label, required, width="auto", sx, children }) => {
    return (
        <Stack
            gap={0.5}
            width={width}
        >
            <H5>{label}{required ? <Required /> : ''}</H5>
            <Box sx={sx}>
                {children}
            </Box>
        </Stack>
    )
}

const InputForm = () => {
    const [agree, setAgree] = useState(false)
    const [open, setOpen] = useState(false)
    const confirmUseForm = useForm({ defaultValues : defaultValues, resolver : yupResolver(schema) })

    const onSubmit = async (d) => {
        setOpen(true)
    }

    return (
        <FormProvider { ...confirmUseForm }>
            <Stack
                component="form"
                onSubmit={confirmUseForm.handleSubmit(onSubmit)}
                alignItems="center"
                width="100%"
                bgcolor={grey[50]}
                px={1}
                py={2}
                gap={2}
            >
                <Stack
                    maxWidth={1000}
                    width="100%"
                >
                    <Stack
                        gap={1}
                    >
                        <H3
                            sx={{
                                borderLeft : `4px solid ${grey[700]}`,
                                pl : 1,
                            }}
                        >
                            新規登録
                        </H3>
                        <Body>
                            報道機関に従事する方でプレスリリースの閲覧を希望される方は、以下のフォームに必要事項を入力のうえ、「登録を申し込む」ボタンをクリックしてください。確認後、弊社より連絡させていただきます。
                        </Body>
                        <Body>
                        </Body>
                    </Stack>
                    <Stack
                        alignItems="center"
                        width="100%"
                        bgcolor="#fff"
                        border={border}
                        borderBottom={0}
                    >
                        <InputBox>
                            <LabeledTextField
                                name="所属企業"
                                label="所属企業"
                                control={confirmUseForm.control}
                                required
                            />
                        </InputBox>
                        <InputBox>
                            <LabeledTextField
                                name="担当媒体_1"
                                label="担当媒体①"
                                control={confirmUseForm.control}
                                required
                            />
                        </InputBox>
                        <InputBox>
                            <LabeledTextField
                                name="担当媒体_2"
                                label="担当媒体②"
                                control={confirmUseForm.control}
                            />
                        </InputBox>
                        <InputBox>
                            <LabeledTextField
                                name="担当媒体_3"
                                label="担当媒体③"
                                control={confirmUseForm.control}
                            />
                        </InputBox>
                        <InputBox>
                            <LV
                                label="名前（姓名）"
                                required
                            >
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    gap={1}
                                >
                                    <Body>姓</Body>
                                    <TextField
                                        name="姓"
                                        control={confirmUseForm.control}
                                        sx={{ maxWidth : 200 }}
                                    />
                                    <Box width={15} />
                                    <Body>名</Body>
                                    <TextField
                                        name="名"
                                        control={confirmUseForm.control}
                                        sx={{ maxWidth : 200 }}
                                    />
                                </Stack>
                            </LV>
                        </InputBox>
                        <InputBox>
                            <LabeledTextField
                                name="電話番号"
                                control={confirmUseForm.control}
                                required
                                sx={{ width : 200 }}
                            />
                        </InputBox>
                        <InputBox>
                            <LabeledTextField
                                name="メールアドレス"
                                control={confirmUseForm.control}
                                required
                            />
                        </InputBox>
                        <InputBox>
                            <LabeledTextField
                                name="メールアドレス_確認用"
                                label="メールアドレス（確認用）"
                                control={confirmUseForm.control}
                                required
                            />
                        </InputBox>
                        <InputBox>
                            <LV
                                label="ネタもとを知った経緯"
                                width={320}
                            >
                                <Select
                                    name="ネタもとを知った経緯"
                                    list={circumstances}
                                    control={confirmUseForm.control}
                                />
                            </LV>
                        </InputBox>
                        <InputBox>
                            <LV
                                label="リリース情報案内メール"
                            >
                                <RadioGroup
                                    name="リリース情報案内メール"
                                    control={confirmUseForm.control}
                                    list={yesno}
                                    row
                                />
                            </LV>
                        </InputBox>
                    </Stack>
                </Stack>
                <TermsOfService setAgree={setAgree} />
                {agree &&
                <BlueContainedButton 
                    label="確認する"
                    type="submit"
                    sx={{ width : 200, mt : 2 }}
                />
                }
                <Link
                    href={link.top}
                    underline="hover"
                    pt={2}
                >
                    <Body>トップに戻る</Body>
                </Link>
            </Stack>
            <ConfirmModal open={open} setOpen={setOpen}/>
        </FormProvider>
    )
}

export default InputForm
