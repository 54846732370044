import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import { messageAtom, spinnerAtom } from 'store/commonAtoms'

const useMyMutation = ({ mutationFn, onSuccessFn, queryKey, successMsg }) => {
    const [, setSpinner] = useAtom(spinnerAtom)
    const [, setMessage] = useAtom(messageAtom)
    const queryClient = useQueryClient()

    const msg = {}

    return useMutation({
        mutationFn : mutationFn,
        onMutate : () => {
            setSpinner(true)
        },
        onSuccess : (ret) => {
            console.log(`success ${ret}`)
            queryClient.invalidateQueries({ queryKey : queryKey })
            if (onSuccessFn) {
                onSuccessFn(ret)
            }
            else {
                msg.type = 'info'
                msg.text = successMsg
            }
        },
        onError : (error, data, context) => {
            msg.type = 'error'
            msg.text = "予期せぬエラーが発生しました。"
            console.log(`error ${error} ${data} ${context}`)
        },
        onSettled : () => {
            setSpinner(false)
            // TODO 暫定対応
            if(!onSuccessFn){
                setMessage(msg)
            }
        },
        retry : 2,
    })
}

export default useMyMutation
