import { Fade, IconButton, Modal, Stack } from '@mui/material'
import { grey } from '@mui/material/colors'
import CloseIcon from '@mui/icons-material/Close'
import { H3, H5, Body } from 'components/styledFonts'
import { BlueContainedButton, BlueOutlinedButton } from 'components/styledButtons'
import { useFormContext } from 'react-hook-form'
import { useAccountRequestMutation } from 'hooks/mutation/accountRequest'

const LV = ({ label, value, underline=true }) => {
    return (
        <Stack
            direction="row"
            alignItems="center"
            columnGap={2}
            rowGap={0.5}
            px={1}
            py={2}
            width="100%"
            bgcolor={grey[100]}
            borderBottom={underline ? `1px solid ${grey[300]}` : 0}
            sx={{
                flexDirection : { sm : "row", xs : "column" }
            }}
        >
            <H5
                sx={{
                    width : { sm : "30%", xs : "100%" },
                    flexShrink : 0
                }}
            >
                {label}
            </H5>
            <Body
                sx={{
                    width : { sm : "70%", xs : "100%" },
                    flexShrink : 0
                }}
            >
                {value}
            </Body>
        </Stack>
    )
}

const ConfirmModal = ({ open, setOpen }) => {
    const { getValues, handleSubmit, reset } = useFormContext()
    const values = getValues()
    const { mutate } = useAccountRequestMutation()

    const onSubmit = () => {
        mutate({
            ...values,
            お知らせメール配信停止DB削除 : values.リリース情報案内メール === "受信しない"
        })
        setOpen(false)
        reset()
    }

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            sx={{
                display : "flex",
                justifyContent : "center",
                alignItems : "center",
            }}
        >
            <Fade in={open} timeout={1000}>
                <Stack
                    p={3}
                    bgcolor="#fff"
                    width="90%"
                    maxHeight="90%"
                    maxWidth={800}
                    gap={2}
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        borderBottom={`2px solid ${grey[600]}`}
                    >
                        <H3>新規登録</H3>
                        <IconButton
                            sx={{ mt : -3, mr : -2 }}
                            onClick={() => setOpen(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Body>入力内容をご確認いただき、よろしければ「申請する」ボタンを押してください。</Body>
                    <Stack
                        sx={{
                            overflowY : "auto",
                            overflowX : "hidden",
                        }}
                    >
                        <LV
                            label="所属企業"
                            value={values.所属企業}
                        />
                        <LV
                            label="担当媒体①"
                            value={values.担当媒体_1}
                        />
                        <LV
                            label="担当媒体②"
                            value={values.担当媒体_2}
                        />
                        <LV
                            label="担当媒体③"
                            value={values.担当媒体_3}
                        />
                        <LV
                            label="名前（姓名）"
                            value={values.姓 + "　" + values.名}
                        />
                        <LV
                            label="電話番号"
                            value={values.電話番号}
                        />
                        <LV
                            label="メールアドレス"
                            value={values.メールアドレス}
                        />
                        <LV
                            label="ネタもとを知った経緯"
                            value={values.ネタもとを知った経緯}
                        />
                        <LV
                            label="リリースメール情報案内メール"
                            value={values.リリース情報案内メール}
                        />
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        pt={2}
                        gap={2}
                    >
                        <BlueOutlinedButton
                            label="修正する"
                            onClick={() => setOpen(false)}
                        />
                        <BlueContainedButton
                            label="申請する"
                            type="submit"
                        />
                    </Stack>
                </Stack>
            </Fade>
        </Modal>
    )
}

export default ConfirmModal
