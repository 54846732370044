import { createTheme } from '@mui/material/styles'
import { blue, deepOrange, grey, indigo, red, yellow } from '@mui/material/colors'

let theme = createTheme({
    breakpoints : {
        values : {
            xs : 0,
            mobile : 480,
            sm : 640,
            md : 1000,
            lg : 1200,
            xl : 1536,
        },
    },
    typography : {
        fontFamily : '"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,Arial,Helvetica,sans-serif',
    },
    mixins : {
        toolbar : {
            minHeight : 75,
        },
    },
    palette : {
        text : {
            primary : grey[700],
        },
        grey400 : {
            main : grey[400],
            contrastText : '#fff',
        },
        grey600 : {
            main : grey[600],
            contrastText : '#fff',
        },
        grey800 : {
            main : grey[800],
            contrastText : '#fff',
        },
        button : {
            main : grey[600],
            contrastText : '#fff',
        },
        blue : {
            main : blue[500],
            contrastText : '#fff',
        },
        indigo : {
            main : indigo[800],
            contrastText : '#fff',
        },
        yellow : {
            main : yellow[700],
            contrastText : '#fff',
        },
        orange : {
            main : deepOrange[500],
            contrastText : '#fff',
        },
        badge : {
            main : red.A400,
            contrastText : '#fff',
        }
    },
    components : {
        MuiAlert : {
            styleOverrides : {
                root : {
                    alignItems : 'center',
                },
            },
        },
        MuiTextField : {
            styleOverrides : {
                root : {
                    /*
                    '& .MuiOutlinedInput-root.Mui-focused fieldset' : {
                        borderColor : grey[600],
                    },
                    */
                    '& .MuiOutlinedInput-input' : {
                        fontSize : 14,
                    },
                },
            },
        },
        MuiCheckbox : {
            styleOverrides : {
                root : {
                    '&.MuiCheckbox-root' : {
                        padding : 4,
                    },
                },
            },
        },
        MuiRadio : {
            styleOverrides : {
                root : {
                    marginLeft : 8,
                    padding : 4,
                },
            },
        },
    },
})

console.log(theme)

export default theme
