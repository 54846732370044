import { Stack } from '@mui/material'
import Header from './Header'
import InputForm from './InputForm'
import Footer from './Footer'
import FlashMessage from './base/FlashMessage'
import Spinner from './base/Spinner'


const Register = () => {
    return (
        <Stack
            alignItems="center"
        >
            <Header />
            <InputForm />
            <Footer />
            <FlashMessage />
            <Spinner />
        </Stack>
    )
}

export default Register
