import { Box, Stack } from '@mui/material'
import { ForMedia } from 'components/styledLabels'
import logo from 'img/new-logo-g.gif'

const Header = () => {
    return (
        <Stack
            alignItems="center"
            py={2}
            width="100%"
            bgcolor="#fff"
            borderBottom="1px solid #ccc"
        >
            <Stack
                direction="row"
                alignItems="center"
                gap={1}
            >
                <Box
                    component="img"
                    src={logo}
                    width={120}
                />
                <ForMedia />
            </Stack>
        </Stack>
    )
}

export default Header
